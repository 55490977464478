document.addEventListener("DOMContentLoaded", function(event)
{
 calendar();
 changeBranch();
 changeCompany();
 changeDepartment();
 changeUser();
 changeVehicle();
 chooseBranch();
 chooseCompany();
 chooseDepartment();
 destroyBranch();
 destroyCompany();
 destroyDepartment();
 destroyEmail();
 destroyNotification();
 destroyUser();
 destroyVehicle();
 detectBrowser();
 loadExcel();
 scrollBar();
 selectBranch();
 selectUser();
 sendEmployee();
 sendUsuario();
 sendVisitor();
 showUser();
 tableBranch();
 tableEmployee();
 tableUser();
 tableUsuario();
 tableVisitor();
 timer();
});

function calendar()
{
 $(".readonly").keydown(function(e){
  e.preventDefault();
 });

 $('.date').datetimepicker({
  allowInputToggle: true,
  defaultDate: new Date(),
  format: 'DD-MM-Y',
  ignoreReadonly: true,
  useCurrent: false,
  widgetPositioning:{
   horizontal: 'auto',
   vertical: 'bottom'
  }
 });

 $('.date_complete').datetimepicker({
  allowInputToggle: true,
  defaultDate: new Date(),
  format: 'DD-MM-Y hh:mm:ss A',
  ignoreReadonly: true,
  useCurrent: false,
  widgetPositioning:{
   horizontal: 'auto',
   vertical: 'bottom'
  }
 });

 $('.date_complete_2').datetimepicker({
  allowInputToggle: true,
  format: 'DD-MM-Y hh:mm:ss A',
  ignoreReadonly: true,
  useCurrent: false,
  widgetPositioning:{
   horizontal: 'auto',
   vertical: 'bottom'
  }
 });
}

function changeBranch()
{
 $(document).on('click', '.changeBranch', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de esta sede?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo también afecta el estado de los departamentos y usuarios afiliados.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/branch/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        text: "<h5>La sede seleccionada ha sido activada</h5>",
        type: "success",
        confirmButtonClass: 'btn-blue'
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Atención!</h6>",
        text: "<h5>La sede seleccionada ha sido desactivada.</h5>",
        type: "warning",
        confirmButtonClass: 'btn-warning'
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado de la sede porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado de la sede porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado de la sede porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado de la sede porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado de la sede, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado de la sede, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado de la sede, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha actualizado el estado de la sede porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>La sede seleccionada no ha sido afectada.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function changeCompany()
{
 $(document).on('click', '.changeCompany', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de esta empresa?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo también afecta el estado de los departamentos, sedes y usuarios afiliados.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/company/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        text: "<h5>La empresa seleccionada ha sido activada</h5>",
        type: "success",
        confirmButtonClass: 'btn-blue'
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Atención!</h6>",
        text: "<h5>La empresa seleccionada ha sido desactivada.</h5>",
        type: "warning",
        confirmButtonClass: 'btn-warning'
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado de la empresa porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado de la empresa porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado de la empresa porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado de la empresa porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado de la empresa, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado de la empresa, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado de la empresa, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha actualizado el estado de la empresa porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>La empresa seleccionada no ha sido afectada.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function changeDepartment()
{
 $(document).on('click', '.changeDepartment', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de este departamento?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo también afecta el estado de los usuarios afiliados.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/department/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        text: "<h5>El departamento seleccionado ha sido activado</h5>",
        type: "success",
        confirmButtonClass: 'btn-blue'
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Atención!</h6>",
        text: "<h5>El departamento seleccionado ha sido desactivado.</h5>",
        type: "warning",
        confirmButtonClass: 'btn-warning'
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del departamento porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del departamento porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del departamento porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del departamento porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del departamento, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del departamento, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado del departamento, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha actualizado el estado del departamento porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El departamento seleccionado no ha sido afectado.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function changeContact()
{
 $(document).on('click', '.changeContact', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de este contacto?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo podría aparecer ó desaparecer al elegir un contacto para los usuarios</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/contact/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        text: "<h5>El contacto seleccionado ha sido activado</h5>",
        type: "success",
        confirmButtonClass: 'btn-blue'
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Atención!</h6>",
        text: "<h5>El contacto seleccionado ha sido desactivado.</h5>",
        type: "warning",
        confirmButtonClass: 'btn-warning'
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del contacto porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del contacto porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del contacto porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del contacto porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del contacto, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del contacto, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado del contacto, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha actualizado el estado del contacto porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El contacto seleccionado no ha sido afectado.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function changeUser()
{
 $(document).on('click', '.changeUser', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de este usuario?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo le concede o activa el acceso a la plataforma, dependiendo del estado.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/user/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        text: "<h5>El usuario seleccionado ha sido activado</h5>",
        type: "success",
        confirmButtonClass: 'btn-blue'
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Atención!</h6>",
        text: "<h5>El usuario seleccionado ha sido desactivado.</h5>",
        type: "warning",
        confirmButtonClass: 'btn-warning'
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del usuario porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del usuario porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha actualizado el estado del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El usuario seleccionado no ha sido afectado.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function changeVehicle()
{
 $(document).on('click', '.changeVehicle', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de este vehículo?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo podría aparecer ó desaparecer al elegir un vehículo para los usuarios</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/vehicle/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        text: "<h5>El vehículo seleccionado ha sido activado</h5>",
        type: "success",
        confirmButtonClass: 'btn-blue'
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Atención!</h6>",
        text: "<h5>El vehículo seleccionado ha sido desactivado.</h5>",
        type: "warning",
        confirmButtonClass: 'btn-warning'
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del vehículo porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del vehículo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del vehículo porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha actualizado el estado del vehículo porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del vehículo, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del vehículo, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado del vehículo, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha actualizado el estado del vehículo porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El vehículo seleccionado no ha sido afectado.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function chooseBranch()
{
 const branch = $('.select_branch');
 const department = $('.select_department');
 const div_department = $('#div_department');

 $('.select_department').select2();

 branch.change(function()
 {
  const url = '/user/departments/'+branch.val();

  div_department.removeClass('d-none');
  department.load(url);
 });

 branch.keyup(function()
 {
  const url = '/user/departments/'+branch.val();

  div_department.removeClass('d-none');
  department.load(url);
 });
}

function chooseCompany()
{
 const branch = $('.select_branch');
 const company = $('.select_company');
 const div_branch = $('#div_branch');

 $('.select_branch').select2();

 company.change(function()
 {
  const url = '/user/branches/'+company.val();

  div_branch.removeClass('d-none');
  branch.load(url);
 });

 company.keyup(function()
 {
  let url = '/user/branches/'+company.val();

  div_branch.removeClass('d-none');
  branch.load(url);
 });
}

function chooseDepartment()
{
 const department = $('.select_department');
 const btn_register = $('#btn_register');

 department.change(function(){
  btn_register.removeClass('d-none');
 });

 department.keyup(function(){
  btn_register.removeClass('d-none');
 });
}

function destroyBranch()
{
 $(document).on('click', '.destroyBranch', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar esta sede?</h5>",
   text: "<h6 class='text-center'>Al hacerlo también se eliminaran todos los departamentos y usuarios vinculados. Esta acción es irreversible.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/branch/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       text: "<h5>La sede seleccionada ha sido eliminada.</h5>",
       type: "success",
       confirmButtonClass: 'btn-blue'
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la sede porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la sede porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la sede porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la sede porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha eliminado la sede, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la sede, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha eliminado la sede, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha eliminado la sede porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>La sede seleccionada no ha sido afectada.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function destroyCompany()
{
 $(document).on('click', '.destroyCompany', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar esta empresa?</h5>",
   text: "<h6 class='text-center'>Al hacerlo también se eliminaran todos los departamentos, sedes y usuarios vinculados. Esta acción es irreversible.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/company/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       text: "<h5>La empresa seleccionada ha sido eliminada.</h5>",
       type: "success",
       confirmButtonClass: 'btn-blue'
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la empresa porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la empresa porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la empresa porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la empresa porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha eliminado la empresa, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la empresa, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha eliminado la empresa, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha eliminado la empresa porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>La empresa seleccionada no ha sido afectada.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function destroyDepartment()
{
 $(document).on('click', '.destroyDepartment', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este departamento?</h5>",
   text: "<h6 class='text-center'>Al hacerlo también se eliminaran todos los usuarios vinculados. Esta acción es irreversible.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/departament/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       text: "<h5>El departamento seleccionado ha sido eliminado.</h5>",
       type: "success",
       confirmButtonClass: 'btn-blue'
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el departamento porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el departamento porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el departamento porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el departamento porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha eliminado el departamento, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el departamento, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el departamento, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha eliminado el departamento porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El departamento seleccionado no ha sido afectado.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function destroyEmail()
{
 $(document).on('click', '.destroyEmail', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar esta notificación?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/notification/email/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       text: "<h5>La notificación seleccionada ha sido eliminada.</h5>",
       type: "success",
       confirmButtonClass: 'btn-blue'
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la notificación porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la notificación porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la notificación porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la notificación porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha eliminado la notificación, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la notificación, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha eliminado la notificación, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha eliminado la notificación porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>La notificación seleccionada no ha sido afectada.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }

   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function destroyNotification()
{
 $(document).on('click', '.destroyNotification', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar esta notificación?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/notification/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       text: "<h5>La notificación seleccionada ha sido eliminada.</h5>",
       type: "success",
       confirmButtonClass: 'btn-blue'
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la notificación porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la notificación porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la notificación porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado la notificación porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha eliminado la notificación, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la notificación, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha eliminado la notificación, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha eliminado la notificación porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>La notificación seleccionada no ha sido afectada.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }

   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function destroyUser()
{
 $(document).on('click', '.destroyUser', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este usuario?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/user/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       text: "<h5>El usuario seleccionado ha sido eliminado.</h5>",
       type: "success",
       confirmButtonClass: 'btn-blue'
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el usuario porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el usuario porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha eliminado el usuario, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el usuario, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el usuario, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha eliminado el usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El usuario seleccionado no ha sido afectado.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function destroyVehicle()
{
 $(document).on('click', '.destroyVehicle', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este vehículo?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/vehicle/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       text: "<h5>El vehículo seleccionado ha sido eliminado.</h5>",
       type: "success",
       confirmButtonClass: 'btn-blue'
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el vehículo porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el vehículo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el vehículo porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha eliminado el vehículo porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. No se ha eliminado el vehículo, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el vehículo, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el vehículo, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>No se ha eliminado el vehículo porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El vehículo seleccionado no ha sido afectado.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function detectBrowser()
{
 let getBrowserInfo = function()
 {
  let ua= navigator.userAgent, tem,
  M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if(/trident/i.test(M[1]))
  {
   tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
   return 'IE '+(tem[1] || '');
  }

  if(M[1]=== 'Chrome')
  {
   tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
   if(tem!= null)
    return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null)
   M.splice(1, 1, tem[1]);
  return M.join(' ');
 };

 if(getBrowserInfo().substr(0,2)=='IE'){
  alert('¡Atención!, Esta usando Internet Explorer, este navegador no es compatible con algunas funciones del sitio web, por favor utilice otro navegador para una mejor experiencia, preferiblemente Google Chrome o Firefox');
 }
}

function loadExcel()
{
 jQuery('.userFile').change(function()
 {
  let filename = jQuery(this).val().split('\\').pop();
  let label = $("label[for='" + $(this).attr('id') + "']");
  $(label).text(filename);
  $('.btn-blue').removeClass('d-none');
 });
}

function scrollBar()
{
 $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar('destroy');
}

function selectBranch()
{
 $(document).on('click', '.selectBranch', function()
 {
  $(this).toggleClass('selected');

  let array_id = [];
  let type = $('#branchTable').attr('tabindex');

  $(".selected").each(function(index)
  {
   array_id.push($(this).attr('id'));
   array_id.toString();
  });

  if($(".selected").length)
  {
   $('#btnBranch').removeClass('d-none');
   $('#btnBranch').addClass('sendBranch');

   sendBranch(array_id, type);
  }
  else
  {
   $('#btnBranch').addClass('d-none');
   $('#btnBranch').removeClass('sendBranch');
  }
 });
}

function selectUser()
{
 $(document).on('click', '.selectUser', function()
 {
  $(this).toggleClass('selected');

  let array_id = [];
  let notification = $('.notification').attr('id');

  $(".selected").each(function(index)
  {
   array_id.push($(this).attr('id'));
   array_id.toString();
  });

  if($(".selected").length)
  {
   $('#btnSend').removeClass('d-none');
   $('#btnSend').addClass('sendEmail');

   sendEmail(array_id, notification);
  }
  else
  {
   $('#btnSend').addClass('d-none');
   $('#btnSend').removeClass('sendEmail');
  }
 });
}

function sendBranch(array_id, type)
{
 $(document).on('click', '.sendBranch', function()
 {
  if(type=='employee' || type=='visitor')
   window.location.href = `/report/symptom/${type}/filter/${array_id}`;

  else if(type=='epidemiological')
   window.location.href = `/report/epidemiological/filter/${array_id}`;
 });
}

function sendEmail(array_id, notification)
{
 $(document).on('click', '.sendEmail', function()
 {
  swal({
   html: true,
   title: "<h5>¿Realmente deseas enviar la notificación a los usuarios seleccionados?</h5>",
   text: "<h6 class='text-justify'>¡Al hacerlo no se puede cancelar la operación de envio!</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-blue",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela el envio!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: `/notification/send/${array_id}/${notification}`,
     type: "get",
     success: function(data)
     {
      $('#userTable').DataTable().ajax.reload();

      if(data=='Enviado')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        text: "<h5>Los correos han sido enviados.</h5>",
        type: "success",
        confirmButtonClass: 'btn-blue'
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Atención!</h6>",
        text: "<h5>Los correos no han podido ser enviados, prueba tu conexión a internet y vuelve a intentarlo.</h5>",
        type: "error",
        confirmButtonClass: 'btn-danger'
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>Los correos no han podido ser enviados porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>Los correos no han podido ser enviados porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>Los correos no han podido ser enviados porque no se ha encontrado la página de respuesta.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>Los correos no han podido ser enviados porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>No se ha hecho la petición de forma correcta. Los correos no han podido ser enviados, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. Los correos no han podido ser enviados, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>¡Error!</h6>",
        text: '<h5>La petición enviada ha sido rechazada por el servidor. Los correos no han podido ser enviados, por favor vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-danger'
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h6 class='text-center'>Atención</h6>",
        text: '<h5>Los correos no han podido ser enviados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
        type: 'error',
        confirmButtonClass: 'btn-warning'
       });
      }
     }
    });

    setTimeout(function(){ window.location.href = '/notification/sent'; }, 1500);
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin enviar</h5>",
     text: "<h6 class='text-center'>Los correos no han sido enviados.</h6>",
     type: "success",
     confirmButtonClass: 'btn-blue'
    });

    return false;
   }
  });
 });
}

function sendEmployee()
{
 let array_id = [];

 $(document).on('click', '.selectEmployee', function()
 {
  $(this).toggleClass('selected');

  let id = $(this).attr('id');
  let index = $.inArray(id, array_id);

  if($(this).hasClass('selected'))
  {
   array_id.push(id);
   array_id.toString();
  }
  else
  {
   array_id.pop(id);
   array_id.toString();
  }
 });

 $(document).on('click', '.sendEmployee', function()
 {
  let date_min = $('#date_min').val();
  let date_max = $('#date_max').val();
  let filter = $('#filter').val();
  let format = $('#format').val();
  let table = $('#tableResult');

  if(filter.trim()=='' || filter==null || filter.length==0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe elegir una opcion en 'Quienes'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-blue"
   });

   $('#filter').focus();

   return false;
  }

  if(filter==='Seleccionados' && array_id.length===0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe seleccionar al menos un empleado si escoge la opción 'Seleccionados'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-blue"
   });

   return false;
  }

  if(format.trim()=='' || format==null || format.length==0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe elegir una opcion en 'Formato'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-blue"
   });

   $('#format').focus();

   return false;
  }

  let dates = {
   array_id: array_id,
   date_min: date_min,
   date_max: date_max,
   filter: filter,
   format: format
  }

  if(format==='Excel')
  {
   let url = "/report/symptom/employee/result?" + $.param(dates)
   window.location = url;
  }
  else
  {
   let url = '/report/symptom/employee/board?' + $.param(dates)
   table.load(url);
  }
 });
}

function sendUsuario()
{
 let array_id = [];

 $(document).on('click', '.selectUsuario', function()
 {
  $(this).toggleClass('selected');

  let id = $(this).attr('id');
  let index = $.inArray(id, array_id);

  if($(this).hasClass('selected'))
  {
   array_id.push(id);
   array_id.toString();
  }
  else
  {
   array_id.pop(id);
   array_id.toString();
  }
 });

 $(document).on('click', '.sendUsuario', function()
 {
  let date_min = $('#date_min').val();
  let date_max = $('#date_max').val();
  let filter = $('#filter').val();
  let format = $('#format').val();
  let table = $('#tableResult');

  if(filter.trim()=='' || filter==null || filter.length==0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe elegir una opcion en 'Quienes'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-blue"
   });

   $('#filter').focus();

   return false;
  }

  if(filter==='Seleccionados' && array_id.length===0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe seleccionar al menos un usuario si escoge la opción 'Seleccionados'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-blue"
   });

   return false;
  }

  if(format.trim()=='' || format==null || format.length==0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe elegir una opcion en 'Formato'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-blue"
   });

   $('#format').focus();

   return false;
  }

  let dates = {
   array_id: array_id,
   date_min: date_min,
   date_max: date_max,
   filter: filter,
   format: format
  }

  if(format==='Excel')
  {
   let url = "/report/epidemiological/result?" + $.param(dates)
   window.location = url;
  }
  else
  {
   let url = '/report/epidemiological/board?' + $.param(dates)
   table.load(url);
  }
 });
}

function sendVisitor()
{
 let array_id = [];

 $(document).on('click', '.selectVisitor', function()
 {
  $(this).toggleClass('selected');

  let id = $(this).attr('id');
  let index = $.inArray(id, array_id);

  if($(this).hasClass('selected'))
  {
   array_id.push(id);
   array_id.toString();
  }
  else
  {
   array_id.pop(id);
   array_id.toString();
  }
 });

 $(document).on('click', '.sendVisitor', function()
 {
  let date_min = $('#date_min').val();
  let date_max = $('#date_max').val();
  let filter = $('#filter').val();
  let format = $('#format').val();
  let table = $('#tableResult');

  if(filter.trim()=='' || filter==null || filter.length==0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe elegir una opcion en 'Quienes'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-outline-success"
   });

   $('#filter').focus();

   return false;
  }

  if(filter==='Seleccionados' && array_id.length===0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe seleccionar al menos un visitante si escoge la opción 'Seleccionados'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-outline-success"
   });

   return false;
  }

  if(format.trim()=='' || format==null || format.length==0)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>¡Advertencia!</h6>",
    text: "<h5>Debe elegir una opcion en 'Formato'.</h5>",
    type: "warning",
    confirmButtonClass: "btn-blue"
   });

   $('#format').focus();

   return false;
  }

  let dates = {
   array_id: array_id,
   date_min: date_min,
   date_max: date_max,
   filter: filter,
   format: format
  }

  if(format==='Excel')
  {
   let url = "/report/symptom/visitor/result?" + $.param(dates)
   window.location = url;
  }
  else
  {
   let url = '/report/symptom/visitor/board?' + $.param(dates)
   table.load(url);
  }
 });
}

function showUser()
{
 $(document).on('click', '.showUser', function()
 {
  let id = $(this).attr('id');

  $.ajax({
   url: '/user/'+id,
   success: function(user)
   {
    swal({
     title: user.name,
     html: true,
     text:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Email: '+user.email+'</p>'
      +' '+
      '<p class="m-0 small">Tipo de Documento: '+user.document_type+'</p>'
      +' '+
      '<p class="m-0 small">N° Identificación: '+user.identification+'</p>'
      +' '+
      '<p class="m-0 small">Sexo: '+user.gender+'</p>'
      +' '+
      '<p class="m-0 small">Eps: '+user.eps+'</p>'
      +' '+
      '<p class="m-0 small">Arl: '+user.arl+'</p>'
      +' '+
      '<p class="m-0 small">Grupo Sanguíneo: '+user.rh+'</p>'
      +' '+
      '<p class="m-0 small">Unidad de Trabajo: '+user.work_unit+'</p>'
      +' '+
      '<p class="m-0 small">Cargo: '+user.charge+'</p>'
      +' '+
      '<p class="m-0 small">Fecha de Ingreso: '+user.admission+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono 1: '+user.phone_1+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono 2: '+user.phone_2+'</p>'
      +' '+
      '<p class="m-0 small">Dirección: '+user.address+'</p>'
      +' '+
      '<p class="m-0 small">Transporte Habitual: '+user.usual_transport+'</p>'
      +' '+
      '<p class="m-0 small">Fecha de Nacimiento: '+user.birthdate+'</p>'
      +' '+
      '<p class="m-0 small">Altura: '+user.height+'</p>'
      +' '+
      '<p class="m-0 small">Peso: '+user.weight+'</p>'
      +' '+
      '<p class="m-0 small">Enfermedades: '+user.diseases+'</p>'
      +' '+
      '<p class="m-0 small">Acerca: '+user.about+'</p>'
      +' '+
      '<p class="m-0 small">Contacto: '+user.contact_name+'</p>'
      +' '+
      '<p class="m-0 small">Relación Contacto: '+user.contact_relationship+'</p>'
      +' '+
      '<p class="m-0 small">Teléfonos Contacto: '+user.contact_phones+'</p>'
      +' '+
      '<p class="m-0 small">Placa Vehículo: '+user.license_plate+'</p>'
      +' '+
      '<p class="m-0 small">Marca Vehículo: '+user.mark+'</p>'
      +' '+
      '<p class="m-0 small">Modelo Vehículo: '+user.model+'</p>'
      +' '+
      '<p class="m-0 small">Color Vehículo: '+user.color+'</p>'
      +' '+
      '<p class="m-0 small">Tipo Vehículo: '+user.type+'</p>'
      +' '+
      '<p class="m-0 small">Departamento: '+user.department_id+'</p>'
      +' '+
      '<p class="m-0 small">Tipo de Usuario: '+user.rol_id+'</p>'
      +' '+
      '<p class="m-0 small">Estado: '+user.state+'</p>'
      +' '+
      '</div>',
     type: 'success',
     confirmButtonClass: 'btn-blue',
     confirmButtonText: 'Cerrar',
     closeOnConfirm: false
    })
   },
   error: function(jqXHR, textStatus, errorThrown)
   {
    if(jqXHR.status===0)
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>¡Error!</h6>",
      text: '<h5>No se han recuperado los datos del usuario porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
      type: 'error',
      confirmButtonClass: 'btn-outline-danger'
     });
    }
    else if(jqXHR.status==404)
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>¡Error!</h6>",
      text: '<h5>No se han recuperado los datos del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
      type: 'error',
      confirmButtonClass: 'btn-outline-danger'
     });
    }
    else if(jqXHR.status==405)
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>¡Error!</h6>",
      text: '<h5>No se han recuperado los datos del usuario porque no se ha encontrado la página de respuesta.</h5>',
      type: 'error',
      confirmButtonClass: 'btn-outline-danger'
     });
    }
    else if(jqXHR.status==500)
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>¡Error!</h6>",
      text: '<h5>No se han recuperado los datos del usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
      type: 'error',
      confirmButtonClass: 'btn-outline-danger'
     });
    }
    else if(textStatus==='parsererror')
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>¡Error!</h6>",
      text: '<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>',
      type: 'error',
      confirmButtonClass: 'btn-outline-danger'
     });
    }
    else if(textStatus==='timeout')
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>¡Error!</h6>",
      text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>',
      type: 'error',
      confirmButtonClass: 'btn-outline-danger'
     });
    }
    else if(textStatus==='abort')
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>¡Error!</h6>",
      text: '<h5>La petición enviada ha sido rechazada por el servidor. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>',
      type: 'error',
      confirmButtonClass: 'btn-outline-danger'
     });
    }
    else
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>Atención</h6>",
      text: '<h5>No se han recuperado los datos del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
      type: 'error',
      confirmButtonClass: 'btn-outline-warning'
     });
    }
   }
  });
 });
}

function tableBranch()
{
 $(document).ready(function()
 {
  $('#branchTable').DataTable({
   "ajax": "/report/symptom/employee/branch/table",
   "columns":[
    {data: 'company_name', name: 'company_name'},
    {data: 'name', name: 'name'},
    {data: 'city', name: 'city'},
    {data: 'address', name: 'address'}
   ],
   "info": false,
   "lengthMenu": [[10, 20, 30, 40, 50, 60, 70, 80, -1], [10, 20, 30, 40, 50, 60, 70, 80, "Todos"]],
   "paging": true,
   "processing": true,
   "responsive": true,
   "select": true,
   "serverSide": true
  });
 });
}

function tableEmployee()
{
 $(document).ready(function()
 {
  let branches = $('#employeeTable').attr('tabindex');

  $('#employeeTable').DataTable({
   "ajax": `/report/symptom/employee/table/${ branches }`,
   "columns":[
    {data: 'nombres', name: 'nombres'},
    {data: 'numero', name: 'numero'},
    {data: 'sitio', name: 'sitio'},
    {data: 'tipo', name: 'tipo'}
   ],
   "info": false,
   "lengthMenu": [[5, 10, 20, 30, 40, 50, 60, 70, 80, -1], [5, 10, 20, 30, 40, 50, 60, 70, 80, "Todos"]],
   "paging": true,
   "processing": true,
   "responsive": true,
   "select": true,
   "serverSide": true
  });
 });
}

function tableUser()
{
 $(document).ready(function()
 {
  $('#userTable').DataTable({
   "ajax": "/user/table",
   "columns":[
    {data: 'name', name: 'name'},
    {data: 'surnames', name: 'surnames'},
    {data: 'email', name: 'email'},
    {data: 'document_type', name: 'document_type'},
    {data: 'identification', name: 'identification'},
    {data: 'rol', name: 'rol'}
   ],
   "info": false,
   "lengthMenu": [[5, 10, 20, 30, 40, 50, 60, 70, 80, -1], [5, 10, 20, 30, 40, 50, 60, 70, 80, "Todos"]],
   "paging": true,
   "processing": true,
   "responsive": true,
   "select": true,
   "serverSide": false
  });
 });
}

function tableUsuario()
{
 $(document).ready(function()
 {
  let branches = $('#usuarioTable').attr('tabindex');

  $('#usuarioTable').DataTable({
   "ajax": `/report/epidemiological/table/${ branches }`,
   "columns":[
    {data: 'nombres', name: 'nombres'},
    {data: 'numero', name: 'numero'},
    {data: 'tipo', name: 'tipo'}
   ],
   "info": false,
   "lengthMenu": [[5, 10, 20, 30, 40, 50, 60, 70, 80, -1], [5, 10, 20, 30, 40, 50, 60, 70, 80, "Todos"]],
   "paging": true,
   "processing": true,
   "responsive": true,
   "select": true,
   "serverSide": true
  });
 });
}

function tableVisitor()
{
 $(document).ready(function()
 {
  let branches = $('#visitorTable').attr('tabindex');

  $('#visitorTable').DataTable({
   "ajax": `/report/symptom/visitor/table/${ branches }`,
   "columns":[
    {data: 'nombres', name: 'nombres'},
    {data: 'numero', name: 'numero'},
    {data: 'company', name: 'company'},
    {data: 'tipo', name: 'tipo'}
   ],
   "info": false,
   "lengthMenu": [[5, 10, 20, 30, 40, 50, 60, 70, 80, -1], [5, 10, 20, 30, 40, 50, 60, 70, 80, "Todos"]],
   "paging": true,
   "processing": true,
   "responsive": true,
   "select": true,
   "serverSide": true
  });
 });
}

function timer()
{
 let url = '/session';
 let minutes = 120;

 $.get(url, function(data, status)
 {
  if(status=='success' && data!='No logueado')
  {
   let number = 60000*minutes;

   setTimeout( function()
   {
    swal({
     title: 'Atención',
     text: 'Su sesión ha caducado, por favor vuelva a loguearse.',
     type: 'warning',
     confirmButtonClass: 'btn-warning'
    });

    location.href = '/logout';
   }, number);
  }
 });
}
