window._ = require('lodash');

try
{
 window.$ = window.jQuery = require('jquery');
 window.Popper = require('popper.js').default;
 window.Bootstrap_Material = require('bootstrap-material-design');
 window.Perfect_Scrollbar = require('./jquery-plugins/perfect-scrollbar.jquery.min.js');
 window.Material_dashboard = require('./material-dashboard/material-dashboard.min.js');
 window.Bootstrap_Select = require('bootstrap-select');
 window.Jasny = require('jasny-bootstrap');
 window.Moment = require('./moment/moment.min.js');
 window.Datetimepicker = require('./datetimepicker/bootstrap-datetimepicker.min.js');
 window.Datatable = require('datatables.net');
 window.Datatable_Bs4 = require('datatables.net-bs4');
 window.Sweetalert = require('sweetalert-ci-dev');
 window.Select = require('./select/select.min.js');
 window.Highcharts = require('highcharts');
 window.Chart = require('./chart/chart.min.js');
 window.Chart_Datalabels = require('./chart/chartjs-plugin-datalabels.min.js');
 window.Method = require('./other/methods.js');
}
catch(e){}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if(token){
 window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}
else{
 console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
